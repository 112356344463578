export const aboutMe = {
  about: [
    "I'm a Software Engineer who focuses on driving the development of software technologies and architecture by using sound engineering principles to develop quality, scalable, secure, and defect free software.",
    "As a proven developer, I've helped organizations scale concepts to production ready systems. Migrated and re-architected systems to scale and exponentially improve security and performance. I've been responsible for establishing corporate processes and training for developers to become more security aware and minded.",
    "My professional interests lie in developing software using proven testing principles, Test Driven Development, promoting architecture design, and testing code with high frequency in order to find and reduce risks and defects in live software.",
    "My technological interests run the gamut from full stack web development, modern application architecture, microservices, APIs, embedded programming, security, automation, and system design. If it requires building something to solve a problem, I'm all in.",
    "I believe we all should be constant learners and always be willing to pick up new technologies, skills, and ideas quickly and making sure the best tools are used for the job at hand.",
    "In my personal time I enjoy spending time with my family, photography and video media, The Oklahoma Sooners, Texas Rangers baseball, and Dallas Cowboys football.",
  ],
  contact: {
    email: "info -- wescodes.tech",
    linkedIn: "https://www.linkedin.com/in/wesleyrhowell",
    github: "https://github.com/wescodestech",
  },
  history: [
    {
      title: "T-Mobile",
      subTitle: "Digital",
      role: "Senior Software Engineer",
      description:
        "A member of the Catalog group developing and delevering software to support product launches and new business intitaitves.",
      date: "2021 - present",
      current: true,
      type: "work",
      logo: "TmLogo",
    },
    {
      title: "Launched This Site!",
      subTitle: "WesCodes.tech",
      description:
        "The purpose of this site is to build foundational components to be used in a personal information sharing platform.",
      role: "Main Developer",
      date: "Summer 2020",
      current: true,
      type: "web",
      logo: "WesCodesLogo",
    },
    {
      title: "CivicPlus",
      subTitle: "Making local government work better.",
      role: "Software Engineer",
      description:
        "A member of the Product Engineering group responsible for developing cloud based services to enable local governments to work more effectively, collect and process payments, and manage HR processes. Developed new software, CI/CD pipelines, unit testing, and technical proofs-of-concept.",
      date: "2018 - 2021",
      current: false,
      type: "work",
      logo: "CpLogo",
    },
    {
      title: "Garmin",
      subTitle: "Automotive In-Vehicle Systems",
      role: "Software Engineer",
      description:
        "A member of the Automotive OEM engineering group designing and implementing embedded and Android based navigation products for Automotive manufactures. Developed best practices and processes for the department on embedded device security and privacy, code auditing, and C/C++ dependency management.",
      date: "2013 - 2018",
      current: false,
      type: "work",
      logo: "GarminLogo",
    },
    {
      title: "Arc Best Technologies (ABF Freight Systems)",
      subTitle: "",
      role: "Programmer Analyst",
      description:
        "Developed support applications to generate testing data from production databases for developers’ application testing, Windows Services, and ASP to .Net application conversion/modernization",
      date: "2012",
      current: false,
      type: "work",
      logo: "ArcBLogo",
    },
    {
      title: "Wesley Howell Photography",
      subTitle: "",
      role: "Owner, Photographer",
      date: "2008 - 2012",
      current: false,
      type: "work",
    },
    {
      title: "Master of Science in Engineering Management",
      subTitle: "",
      role: "The University of Kansas",
      date: "December 2020",
      current: false,
      type: "school",
      logo: "KuLogo",
    },
    {
      title: "Bachelor of Science in Computer Science",
      subTitle: "",
      role: "The University of Oklahoma",
      date: "2009 - 2013",
      current: false,
      type: "school",
      logo: "OuLogo",
    },
  ],
};
